import { useGlobalStore } from "@/stores/globalStore";
import { ldInit, useLDFlag, useLDReady } from "launchdarkly-vue-client-sdk";
import { Ref, ref } from "vue";

export class FeatureFlagService {
    constructor(private readonly clientKey: string) {}

    /**
     * # How to use
     * const featureFlagService = inject<FeatureFlagService>("featureFlagService")!;
     * const hasFeature = featureFlagService.hasFeature("flag-key");
     * populate this object "hasFeature" on the data or setup in the component
     *
     * # How to display
     * <div v-if="hasFeature.initialized">
     *      <p>Display a loading</p>
     *      <div v-if="hasFeature.hasFeature">
     *          display the feature
     *      </div>
     *  </div>
     *
     */
    hasFeature(flag: string): {
        initialized: Ref<boolean>;
        hasFeature: Ref<boolean>;
    } {
        if (!this.clientKey)
            return { initialized: ref(false), hasFeature: ref(false) };

        const globalStore = useGlobalStore();

        const context = {
            kind: "Organization",
            key: globalStore.currentOrganization?.id?.toString(),
            userId: globalStore.userContext?.id,
        };

        const ldReady = useLDReady();
        if (!ldReady.value) {
            ldInit({ clientSideID: this.clientKey, context });
        }

        const flagValue = useLDFlag(flag, false);

        return { initialized: ldReady, hasFeature: flagValue };
    }
}
