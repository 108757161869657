import { IFormStyles } from "./components/form/FormInterface";
import { ComponentStyles } from "./interfaces";

export const EMPTY_STYLES = <ComponentStyles>{
    cssClass: null,
    color: null,
    backgroundColor: null,
    fontColor: null,
    fontSize: null,
    fontFamily: null,
    textAlign: null,
    paddingLeft: null,
    paddingRight: null,
    paddingTop: null,
    paddingBottom: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    borderRadius: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: null,
    boxShadow: null,
    fullWidth: false,
    hoverColor: null,
    outlineColor: null,
    activeOutlineColor: null,
    outlineWeight: null,
};

export const EMPTY_FORM_STYLES = <IFormStyles>{
    ...EMPTY_STYLES,
    primaryColor: null,
    secondaryColor: null,
    headingText: null,
    bodyText: null,
    buttonColor: null,
    buttonText: null,
    fieldLabel: null,
    fieldBorder: null,
};

export const ComponentErrors = {
    DuplicateComponent: "Component can only be used once",
    InvalidComponent: "Invalid component",
    BackupGiftArrayRequired:
        "Back up gift array is required when using smart gift arrays.",
    InvalidCampaign:
        "This component requires a campaign with a giving goal or total gift goal.",
};
