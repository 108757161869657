export enum AccountState {
    ActionRequired = 0,
    Pending = 1,
    Active = 2,
    Disabled = 3,
    Deleted = 4,
    VerificationNeeded = 5,
    Retired = 6,
    ApprovedPending = 7,
    Rejected = 8,
    AwaitingSignature = 9,
    ActiveDepositHold = 10
}

export class AccountStateHelper {
    static isPending(state: AccountState | null): boolean {

        if (state === null) {
            return true; // default to true if null
        }

        switch (state) {
            case AccountState.Pending:
            case AccountState.ActionRequired:
            case AccountState.VerificationNeeded:
            case AccountState.ApprovedPending:
            case AccountState.AwaitingSignature:
                return true;
            default:
                return false;
        }
    }
}
