export enum ContactMethodType {
    Unknown = 0,

    HomeEmail = 10,
    WorkEmail = 11,
    OtherEmail = 12,

    HomePhone = 20,
    WorkPhone = 21,
    MobilePhone = 22,

    Other = 99
}