import { EmailTemplateType } from "@/enums";
import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultAcknowledgementEmail } from "./DefaultAcknowledgementEmail";
import { DefaultCancelledRecurringScheduleEmail } from "./DefaultCancelledRecurringScheduleEmail";
import { DefaultDonationConfirmationEmail } from "./DefaultDonationConfirmationEmail";
import { DefaultFailedRecurringChargeEmail } from "./DefaultFailedRecurringChargeEmail";
import { DefaultLeadConfirmationEmail } from "./DefaultLeadConfirmationEmail";
import { DefaultNewRecurringScheduleEmail } from "./DefaultNewRecurringScheduleEmail";
import { DefaultSuccessfulRecurringChargeEmail } from "./DefaultSuccessfulRecurringChargeEmail";
import { DefaultUpcomingChargeEmail } from "./DefaultUpcomingChargeEmail";
import { DefaultUpdatedRecurringScheduleEmail } from "./DefaultUpdatedRecurringScheduleEmail";

export const getDefaultEmailTemplateContent = (
    type: EmailTemplateType,
    design: EmailDesign
): any | null => {
    const templates = new Map<EmailTemplateType, any | null>();
    templates.set(
        EmailTemplateType.Acknowledgement,
        DefaultAcknowledgementEmail(design)
    );
    templates.set(
        EmailTemplateType.ConfirmationDonation,
        DefaultDonationConfirmationEmail(design)
    );
    templates.set(
        EmailTemplateType.ConfirmationLead,
        DefaultLeadConfirmationEmail(design)
    );
    templates.set(
        EmailTemplateType.RecurringChargeCancel,
        DefaultCancelledRecurringScheduleEmail(design)
    );
    templates.set(
        EmailTemplateType.RecurringChargeFailure,
        DefaultFailedRecurringChargeEmail(design)
    );
    templates.set(
        EmailTemplateType.RecurringChargeNew,
        DefaultNewRecurringScheduleEmail(design)
    );
    templates.set(
        EmailTemplateType.RecurringChargeRun,
        DefaultSuccessfulRecurringChargeEmail(design)
    );
    templates.set(
        EmailTemplateType.RecurringChargeUpcoming,
        DefaultUpcomingChargeEmail(design)
    );
    templates.set(
        EmailTemplateType.RecurringChargeUpdate,
        DefaultUpdatedRecurringScheduleEmail(design)
    );

    if (templates.has(type)) {
        return JSON.stringify(templates.get(type));
    }

    return {};
};
