import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import { useGlobalStore } from "@/stores/globalStore";
import { AppUrls } from "@/constants/AppUrls";
import Permission from "@/interfaces/Permission";
import { Role } from "@/enums/Role";
import { AccessLevel } from "@/enums/AccessLevel";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        alias: AppUrls.Dashboard,
        name: "home-dashboard",
        component: () => import("./components/home/HomeDashboard.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/activities",
        alias: AppUrls.Activities,
        name: "home-activities",
        component: () => import("./components/home/HomeActivityFeed.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Unauthorized,
        name: "un-authorized",
        component: () => import("./components/Unauthorized.vue"),
    },
    {
        path: AppUrls.Account.Login,
        name: "account-login",
        component: () => import("./components/account/AccountLogin.vue"),
    },
    {
        path: AppUrls.Account.Logout,
        name: "account-logout",
        component: () => import("./components/account/AccountLogout.vue"),
    },
    {
        path: AppUrls.Account.Lockout,
        name: "account-lockout",
        component: () => import("./components/account/AccountLockout.vue"),
    },
    {
        path: AppUrls.Account.ForgotPassword,
        name: "account-forgotpassword",
        component: () => import("./components/account/AccountForgotPassword.vue"),
    },
    {
        path: AppUrls.Account.ForgotPasswordConfirmation,
        name: "account-forgotpassword-confirmation",
        component: () => import("./components/account/AccountForgotPasswordConfirmation.vue"),
    },
    {
        path: AppUrls.Account.ResetPassword,
        name: "account-resetpassword",
        component: () => import("./components/account/AccountResetPassword.vue"),
    },
    {
        path: AppUrls.Account.ResetPasswordConfirmation,
        name: "account-resetpassword-confirmation",
        component: () => import("./components/account/AccountResetPasswordConfirmation.vue"),
    },
    {
        path: AppUrls.Account.SsoCallback,
        name: "account-sso-callback",
        component: () => import("./components/account/AccountSsoCallback.vue"),
    },
    {
        path: AppUrls.Account.ExchangeToken,
        name: "exchange-token",
        component: () => import("./components/account/ExchangeToken.vue"),
    },
    {
        path: AppUrls.Account.Verify,
        name: "account-verify",
        component: () => import("./components/account/AccountVerify.vue"),
    },
    {
        path: AppUrls.Campaigns.List,
        name: "campaign-list",
        component: () => import("./components/campaigns/AllCampaignList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Campaigns.List,
        name: "segment-list",
        component: () => import("./components/campaigns/AllCampaignList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Campaigns.Create,
        component: () => import("./components/campaigns/CampaignEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Campaigns.Edit,
        component: () => import("./components/campaigns/CampaignEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Campaigns.View,
        component: () => import("./components/campaigns/CampaignView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Segments.View,
        name: "segment-view",
        component: () => import("./components/segments/SegmentView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Donors.List,
        component: () => import("./components/donors/DonorList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Donors.View,
        component: () => import("./components/donors/DonorView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Donors.ViewRecurringGiftRoute,
        component: () => import("./components/donors/DonorView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Donors.Edit,
        component: () => import("./components/donors/DonorEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.ApiKeys.List,
        component: () => import("./components/settings/apiKeys/ApiKeyList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Org_Admin, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.ApiKeys.View,
        component: () => import("./components/settings/apiKeys/ApiKeyView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Org_Admin, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.ApiKeys.Create,
        component: () => import("./components/settings/apiKeys/ApiKeyCreate.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Org_Admin, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.CustomFields.List,
        component: () => import("./components/settings/custom-fields/CustomFieldList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Exports.List,
        component: () => import("./components/exports/ExportList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Exports.ScheduledExportList,
        name: "scheduled-export-list",
        component: () => import("./components/exports/ScheduledExportList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Exports.ViewScheduledExport,
        component: () => import("./components/exports/ScheduledExportList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Premiums.List,
        component: () => import("./components/premiums/PremiumList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Premiums.Create,
        component: () => import("./components/premiums/PremiumEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }],
        },
    },
    {
        path: AppUrls.Premiums.Edit,
        component: () => import("./components/premiums/PremiumEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }],
        },
    },
    {
        path: AppUrls.Projects.List,
        component: () => import("./components/projects/ProjectList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Projects, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Projects.View,
        name: "project-view",
        component: () => import("./components/projects/ProjectView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Projects, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Projects.Edit,
        component: () => import("./components/projects/ProjectEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Projects, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Projects.Create,
        component: () => import("./components/projects/ProjectEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Projects, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Gifts.List,
        component: () => import("./components/gifts/AllGiftList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Gifts.List,
        name: "recurring-gift-list",
        component: () => import("./components/gifts/AllGiftList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Gifts.View,
        component: () => import("./components/gifts/GiftView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Dashboard,
        name: "settings-dashboard",
        component: () => import("./components/settings/organization/OrganizationView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Organization.View,
        component: () => import("./components/settings/organization/OrganizationView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Sso.Configure,
        component: () => import("./components/settings/sso/SsoConfigure.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Users.List,
        component: () => import("./components/settings/users/UserList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Users.Edit,
        component: () => import("./components/settings/users/UserEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [
                { role: Role.AccountSettings, accessLevel: AccessLevel.ViewAndEdit }
            ] as Permission[],
        }
    },
    {
        path: AppUrls.Settings.Users.EditOwnAccount,
        name: "edit-own-account",
        component: () => import("./components/settings/users/UserEditOwnAccount.vue"),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: AppUrls.Settings.Users.Create,
        component: () => import("./components/settings/users/UserCreate.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.List,
        component: () => import("./components/settings/payment-providers/PaymentProviderList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.Create,
        component: () => import("./components/settings/payment-providers/PaymentProviderCreate.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.Edit,
        component: () => import("./components/settings/payment-providers/PaymentProviderEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.Return,
        component: () => import("./components/settings/payment-providers/PaymentProviderReturn.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        name: AppUrls.Settings.PaymentProviders.Refresh,
        path: AppUrls.Settings.PaymentProviders.Refresh,
        component: () => import("./components/settings/payment-providers/PaymentProviderCreate.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.LinkPayPal,
        component: () => import("./components/settings/payment-providers/paypal/LinkPayPalAccount.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.RefreshPayPal,
        component: () => import("./components/settings/payment-providers/paypal/LinkPayPalAccount.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.ReturnPayPal,
        component: () => import("./components/settings/payment-providers/paypal/LinkPayPalAccount.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.CreateSecondary,
        component: () => import("./components/settings/payment-providers/PaymentProviderCreateSecondary.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.PaymentProviders.EditSecondary,
        component: () => import("./components/settings/payment-providers/PaymentProviderCreateSecondary.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.Financial, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Integrations.ListView,
        component: () => import("./components/settings/integrations/IntegrationList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Integrations.Virtuous,
        component: () => import("./components/settings/integrations/VirtuousIntegration.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.Settings.Integrations.VirtuousCallback,
        component: () => import("./components/settings/integrations/VirtuousIntegrationCallback.vue")
    },
    {
        path: AppUrls.Settings.Integrations.DoubleTheDonation,
        component: () => import("./components/settings/integrations/DoubleTheDonation.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.Settings.Integrations.GoogleAnalytics,
        component: () => import("./components/settings/integrations/GoogleAnalytics.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.Settings.Design,
        component: () => import("./components/settings/design/GlobalDesign.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.DonorPortalSettings,
        component: () => import("./components/settings/donor-portal/DonorPortalSettings.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Webhooks.List,
        name: "webhook-list",
        component: () => import("./components/settings/webhooks/WebhookList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Webhooks.View,
        name: "webhook-view",
        component: () => import("./components/settings/webhooks/WebhookView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Webhooks.Logs,
        name: "webhook-log-list",
        component: () => import("./components/settings/webhooks/WebhookLogList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Webhooks.LogView,
        name: "webhook-log-view",
        component: () => import("./components/settings/webhooks/WebhookLogView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Terminal.List,
        name: "terminal-list",
        component: () => import("./components/settings/virtual-terminal/VirtualTerminalList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.VirtualTerminal, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Terminal.Create,
        component: () => import("./components/settings/virtual-terminal/VirtualTerminalEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.VirtualTerminal, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Settings.Terminal.Edit,
        component: () => import("./components/settings/virtual-terminal/VirtualTerminalEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.VirtualTerminal, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        },
    },
    {
        path: AppUrls.Pages.List,
        name: "page-list",
        component: () => import("./components/pages/PageList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [
                { role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }
            ] as Permission[],
        }
    },
    {
        path: AppUrls.Pages.Versions,
        name: "page-versions",
        component: () => import("./components/pages/PageVersionList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [
                { role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }
            ] as Permission[],
        }
    },
    {
        path: AppUrls.Pages.Create,
        name: "page-create",
        component: () => import("./builder/PageBuilder.vue"),
        meta: {
            requiresAuth: true,
            permissions: [
                { role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }
            ] as Permission[],
        }
    },
    {
        path: AppUrls.Pages.Edit,
        name: "page-edit",
        component: () => import("./builder/PageBuilder.vue"),
        meta: {
            requiresAuth: true,
            permissions: [
                { role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }
            ] as Permission[],
        }
    },
    {
        path: AppUrls.Pages.PreviewRoute,
        name: "page-preview",
        component: () => import("./builder/PagePreview.vue"),
        meta: {
            requiresAuth: true,
            permissions: [
                { role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }
            ] as Permission[],
        }
    },
    {
        path: AppUrls.RecurringGifts.View,
        component: () => import("./components/donors/DonorView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.View }] as Permission[],
        }
    },
    {
        path: AppUrls.RecurringGifts.Edit,
        component: () => import("./components/recurring-gifts/RecurringGiftEdit.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.GiftsDonorsExport, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.EmailTemplate.List,
        name: "email-template-list",
        component: () => import("./components/email-templates/EmailTemplateList.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.View }] as Permission[],
        }
    },
    {
        path: AppUrls.EmailTemplate.Create,
        name: "email-template-create",
        component: () => import("./components/email-templates/EmailTemplateBuilder.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.EmailTemplate.Edit,
        name: "email-template-edit",
        component: () => import("./components/email-templates/EmailTemplateBuilder.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.EmailTemplate.Settings,
        name: "email-template-settings",
        component: () => import("./components/email-settings/EmailSettings.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.PagesPremiumsCampaignsAndEmails, accessLevel: AccessLevel.ViewAndEdit }] as Permission[],
        }
    },
    {
        path: AppUrls.Settings.Notifications,
        component: () => import("./components/settings/notifications/NotificationView.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.Account.FirstLogin,
        name: "account-first-login",
        component: () => import("./components/account/AccountFirstLogin.vue"),
    },
    {
        path: AppUrls.Account.FirstLoginConfirmation,
        name: "account-first-login-confirmation",
        component: () => import("./components/account/AccountFirstLoginConfirmation.vue"),
    },
    {
        path: AppUrls.Settings.WhiteLabel,
        component: () => import("./components/settings/white-label/WhiteLabelConfigure.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.AccountSettings, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.VirtualTerminal.View,
        name: "virtual-terminal",
        component: () => import("./components/virtual-terminal/VirtualTerminal.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.VirtualTerminal, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.VirtualTerminal.ViewTerminal,
        component: () => import("./components/virtual-terminal/VirtualTerminal.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ role: Role.VirtualTerminal, accessLevel: AccessLevel.View }] as Permission[],
        },
    },
    {
        path: AppUrls.ProductFeedback.View,
        component: () => import("./components/product-feedback/ProductFeedback.vue"),
        meta: {
            requiresAuth: true,
            permissions: [{ accessLevel: AccessLevel.View }] as Permission[],
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.length === 0) {
        //no route matched, redirect to dashboard
        next(AppUrls.Dashboard);
    } else {
        const globalStore = useGlobalStore();

        if (to.meta.requiresAuth && !globalStore.checkAuthentication()) {
            // this route requires auth
            // user is not authenticated, redirect to login page.
            next({ path: AppUrls.Account.Login, query: { returnUrl: to.fullPath } });
        }
        else if (await globalStore.shouldEnableTwoFactor()) {
            if (to.name !== "edit-own-account" && to.name !== "account-logout") {

                // redirect to edit account if 2fa is enforced and user needs to setup mfa
                next({ name: "edit-own-account", replace: true });
            }

            // 2fa is enforced and user has not enabled mfa yet. Allow to edit own account or logout
            next();
        }
        else if (to.meta.permissions) {
            //this route is permission protected, check if user has permission
            const hasPermission = await globalStore.hasPermissions(to.meta.permissions as Permission[]);
            if (hasPermission) {
                next();
            } else {
                //check if user only has virtual terminal view permission
                if (await globalStore.isVirtualTerminalUser()) {
                    next(AppUrls.VirtualTerminal.View);
                }
                next(AppUrls.Unauthorized);
            }
        } else {
            //check if user only has virtual terminal view permission
            if (to.name !== "edit-own-account"
                && to.name !== "account-logout"
                && await globalStore.isVirtualTerminalUser()) {
                next(AppUrls.VirtualTerminal.View);
            }

            next();
        }
    }
});

router.afterEach((to, from) => {
    const globalStore = useGlobalStore();

    //set the current route path in the globalStore
    globalStore.setCurrentRoute(to.path as string);
});

export default router;
