export enum CustomFieldDataType {
    Boolean = 0,
    Date = 1,
    Decimal = 2,
    Text = 3,
    List = 4,
    Link = 5,
    Integer = 6,
    Paragraph = 7,
    Multiselect = 8
}
