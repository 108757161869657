<template>
    <toast-container position="top-right" storeSelection="globalStore" />
    <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/globalStore';
import { AppUrls } from '@/constants/AppUrls';
import ToastContainer from '@/components/shared/toast/ToastContainer.vue';
import chameleon from "@chamaeleonidae/chmln"
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'App',
    components: {
        ToastContainer,
    },
    setup() {
        return { globalStore: useGlobalStore(), router: useRouter() };
    },
    async beforeMount() {
        if (this.globalStore.checkAuthentication()) {
            await this.globalStore.loadPermissions();
        }
    },
    mounted() {
        const key = process.env.VUE_APP_CHAMELEON_API_KEY;
        if (key) {
            chameleon.init(key);
        }
    },
    watch: {
        'globalStore.isAuthenticated': async function (val) {
            if (val) {
                await this.globalStore.loadPermissions();
                const key = process.env.VUE_APP_CHAMELEON_API_KEY;
                if (key) {
                    if (!this.globalStore.userContext) {
                        await this.globalStore.getUserContext();
                        await this.globalStore.getCurrentOrganization();
                    }
                    const data = {
                        email: this.globalStore.userContext?.email,
                        name: `${this.globalStore.userContext?.firstName} ${this.globalStore.userContext?.lastName}`,
                        first_name: this.globalStore.userContext?.firstName,
                        rd2_email: this.globalStore.userContext?.email,
                        rd2_name: `${this.globalStore.userContext?.firstName} ${this.globalStore.userContext?.lastName}`,
                        rd2_created: this.globalStore.userContext?.createdDate,
                        rd2_role: this.globalStore.userRole(),
                        rd2_login_count: this.globalStore.userContext?.loginCount,
                        company: {
                            rd2_uid: this.globalStore.currentOrganization?.id,
                            rd2_created: this.globalStore.currentOrganization?.createdDate,
                            rd2_name: this.globalStore.currentOrganization?.name,
                        }
                    }

                    if (this.globalStore.userContext?.id) {
                        chameleon.identify(this.globalStore.userContext?.id, data);
                    }
                }
            }
            else {
                //clear out store
                this.globalStore.setUserContext(null);
                this.globalStore.setCurrentOrganization(null);
                this.globalStore.setPermissions([]);

                //send all open tabs to logout
                this.router.push(AppUrls.Account.Logout);
            }
        }
    }
});
</script>
