import { ICustomComponent } from "@/builder/interfaces";
import { getEmptyComponent } from "@/builder/BuilderUtilities";
import { ComponentType } from "../../enums";
import { IPage } from "../page/PageInterface";

export enum ButtonActionType {
    Link = 0,
    NextStep = 1,
    PrevStep = 2,
    Submit = 3,
    Modal = 4,
    Function = 5,
    Custom = 6,
}

export enum ActionTrigger {
    Click = 0,
    Hover = 1,
}

export interface Action {
    trigger: ActionTrigger;
    type: ButtonActionType;
    values: string | string[] | (() => void) | null;
}

export interface IButtonComponent extends ICustomComponent {
    controls: {
        canMove: boolean;
        canEdit: boolean;
        canDelete: boolean;
        allowChildren: false;
        allowMultiple: boolean;
    };
    customData: {
        className: string | null;
        text: string | null;
        buttonSize: string | null;
        icon: string | null;
        iconPosition: string | null;
        action: Action | null;
        alignment: string | null;
    };
    errors: [];
}

export const getButtonComponent = (page: IPage) =>
    getEmptyComponent(ComponentType.Button, page);
