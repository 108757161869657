export enum PaymentProviderType {
    None = 0,
    VirtuousPayments = 1,
    Stripe = 2,
    AuthorizeNet = 3,
    PTC = 4,
    PayPal = 5,
    PayPalV2 = 6,
    Stax = 7,
}
