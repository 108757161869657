export enum CustomFieldDataFormatter {
    None = 0,
    Numeric = 1,
    NumericWithFractional = 2,
    Currency = 3,
    CurrencyWithDecinals = 4,
    Dropdown = 5,
    ListRadio = 6,
    ListCheckBox = 7,
    BooleanButton = 8,
    BooleanRadio = 9,
}
