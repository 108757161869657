import { ICustomComponent } from "@/builder/interfaces";
import { generateUID } from "@/builder/BuilderUtilities";
import { ComponentType } from "../../enums";
import { EMPTY_STYLES } from "@/builder/Constants";

export enum InputType {
    Name,
    Email,
    PhoneNumber,
    Comments,
    Address,
    BusinessName,
}

export interface ITextInputCustomData {
    type: InputType;
    label: string;
    required: boolean;
    validation: string[];
}

export interface INameInputCustomData extends ITextInputCustomData {
    firstName: true;
    lastName: true;
    title: boolean;
    middleName: boolean;
    suffix: boolean;
    titleLabel: string;
    firstNameLabel: string;
    middleNameLabel: string;
    lastNameLabel: string;
    suffixLabel: string;
    titlePlaceholder: string;
    firstNamePlaceholder: string;
    middleNamePlaceholder: string;
    lastNamePlaceholder: string;
    suffixPlaceholder: string;
}

export interface IBusinessNameInputCustomData extends ITextInputCustomData {
    label: string;
    placeholder: string;
    onBehalfOfText: string;
}

export interface IAddressInputCustomData extends ITextInputCustomData {
    billingAddress1Label: string | null;
    billingAddress1Placeholder: string | null;
    billingAddress2Placeholder: string | null;
    billingCityPlaceholder: string | null;
    billingStatePlaceholder: string | null;
    billingPostalPlaceholder: string | null;
    billingCountryPlaceholder: string | null;

    enableShippingAddress: boolean;
    shippingAddress1Label: string | null;
    shippingAddress1Placeholder: string | null;
    shippingAddress2Placeholder: string | null;
    shippingCityPlaceholder: string | null;
    shippingStatePlaceholder: string | null;
    shippingPostalPlaceholder: string | null;
    shippingCountryPlaceholder: string | null;
}

export interface ITextInputComponent extends ICustomComponent {
    controls: {
        canMove: true;
        canEdit: true;
        canDelete: true;
        allowChildren: false;
        allowMultiple: true;
    };
    customData:
        | ITextInputCustomData
        | INameInputCustomData
        | IAddressInputCustomData;
}

export const getTextInputComponent = () =>
    <ITextInputComponent>{
        id: generateUID(),
        parentId: null,
        type: ComponentType.TextInput,
        styles: {
            ...EMPTY_STYLES,
        },
        controls: {
            canMove: true,
            canEdit: true,
            canDelete: true,
            allowChildren: false,
            allowMultiple: true,
        },
        components: [],
        customData: {
            type: InputType.Name,
            label: "Name",
            required: true,
            validation: ["required"],
            firstName: true,
            lastName: true,
            title: false,
            middleName: false,
            suffix: false,
            businessName: false,
            titleLabel: "Title",
            titlePlaceholder: "Title",
            firstNameLabel: "First Name",
            firstNamePlaceholder: "First",
            middleNameLabel: "Middle Name",
            middleNamePlaceholder: "Middle",
            lastNameLabel: "Last Name",
            lastNamePlaceholder: "Last",
            suffixLabel: "Suffix",
            suffixPlaceholder: "Suffix",
            businessNameLabel: "Business Name",
            businessNamePlaceholder: "Business Name",
        },
        errors: [],
        label: "Text Input",
    };
