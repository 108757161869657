export enum MergeTag {
    Donor_FirstName = "Donor.FirstName",
    Donor_LastName = "Donor.LastName",
    Donor_OrganizationName = "Donor.OrganizationName",
    Donor_Address = "Donor.Address",
    Donor_Email = "Donor.Email",
    Donor_Phone = "Donor.Phone",

    Transaction_ID = "Transaction.ID",
    Transaction_Cost = "Transaction.Cost",
    Transcation_NetAmount = "Transaction.NetAmount",
    Transaction_TotalAmount = "Transaction.TotalAmount",
    Transaction_Premium = "Transaction.Premium",
    Transaction_ProjectSplit = "Transaction.ProjectSplit",
    Transaction_Frequency = "Transaction.Frequency",

    DonorPortal_Login = "DonorPortal.Login",
}

export const DonorMergeTags = [
    {
        value: MergeTag.Donor_FirstName,
        title: "First Name",
    },
    {
        value: MergeTag.Donor_LastName,
        title: "Last Name",
    },
    {
        value: MergeTag.Donor_OrganizationName,
        title: "Organization Name",
    },
    {
        value: MergeTag.Donor_Address,
        title: "Address",
    },
    {
        value: MergeTag.Donor_Email,
        title: "Email",
    },
    {
        value: MergeTag.Donor_Phone,
        title: "Phone",
    },
];

export const TransactionMergeTags = [
    {
        value: MergeTag.Transaction_Cost,
        title: "Cost",
    },
    {
        value: MergeTag.Transcation_NetAmount,
        title: "Net Amount",
    },
    {
        value: MergeTag.Transaction_TotalAmount,
        title: "Total Amount",
    },
    {
        value: MergeTag.Transaction_Premium,
        title: "Premium",
    },
    {
        value: MergeTag.Transaction_ProjectSplit,
        title: "Project Split",
    },
    {
        value: MergeTag.Transaction_Frequency,
        title: "Frequency",
    },
];

