export enum Locales {
    Auto = 'auto',
    Arabic = 'ar',
    Bulgarian_Bulgaria = 'bg',
    Czech_Czech_Republic = 'cs',
    Danish_Denmark = 'da',
    German_Germany = 'de',
    Greek_Greece = 'el',
    English = 'en',
    English_United_Kingdom = 'en-GB',
    Spanish_Spain = 'es',
    Spanish_Latin_America = 'es-419',
    Estonian_Estonia = 'et',
    Finnish_Finland = 'fi',
    Filipino_Philipines = 'fil',
    French_France = 'fr',
    French_Canada = 'fr-CA',
    Hebrew_Israel = 'he',
    Croatian_Croatia = 'hr',
    Hungarian_Hungary = 'hu',
    Indonesian_Indonesia = 'id',
    Italian_Italy = 'it',
    Japanese_Japan = 'ja',
    Korean_Korea = 'ko',
    Lithuanian_Lithuania = 'lt',
    Latvian_Latvia = 'lv',
    Malay_Malaysia = 'ms',
    Maltese_Malta = 'mt',
    Norwegian_Bokmål = 'no',
    Dutch_Netherlands = 'nl',
    Polish_Poland = 'pl',
    Portuguese_Portugal = 'pt',
    Portuguese_Brazil = 'pt-BR',
    Romanian_Romania = 'ro',
    Russian_Russia = 'ru',
    Slovak_Slovakia = 'sk',
    Slovenian_Slovenia = 'sl',
    Swedish_Sweden = 'sv',
    Thai_Thailand = 'th',
    Turkish_Turkey = 'tr',
    Vietnamese_Vietnam = 'vi',
    ChineseSimplified_China = 'zh',
    ChineseTraditional_Hong_Kong = 'zh-HK',
    ChineseTraditional_Taiwan = 'zh-TW',
}