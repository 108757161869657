export enum EmailTemplateType {
    ConfirmationDonation = 1,
    ConfirmationLead = 2,
    RecurringChargeNew = 3,
    RecurringChargeUpcoming = 4,
    RecurringChargeRun = 5,
    RecurringChargeCancel = 6,
    RecurringChargeUpdate = 7,
    RecurringChargeFailure = 8,
    UpcomingCharge = 9,
    Acknowledgement = 10,
    PaymentDisputes = 11,
    UnsuccessfulIntegrations = 12,
    PremiumInventoryLow = 14,
    MerchantAccountUpdate = 15,
    MerchantAccountApproved = 16,
    MerchantAccountRejected = 17,
}
