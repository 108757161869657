/**
 *
 * @param amount
 * @returns number
 */
export const amountToNumber = (amount: string | number | null) => {
    //if amount is a number return it
    if (typeof amount === "number") return amount;

    //exit if amount is null or empty
    if (!amount || amount.length === 0) return 0;

    //remove currency formatting and return as number
    amount = amount?.replaceAll(",", "");
    amount = amount?.replace(/\$/g, "");

    return Number.parseFloat(amount ?? "0");
};
