/**
 *
 * @param val
 * @returns string
 */
export const formatCurrency = (val: string) => {
    if (!val) return "0.00";

    const parsedAmount = parseFloat(
        `${!val ? 0 : val}`.replaceAll(",", "")
    ).toFixed(2);

    return Number(parsedAmount).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

/**
 *
 * @param hex
 * @param a
 * @returns rgba string
 */
export const hexToRgbA = (hex: string, a: number) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        /*return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';*/

        const rgba = {
            r: (c >> 16) & 255,
            g: (c >> 8) & 255,
            b: c & 255,
            a: a,
        };

        return rgbaToString(rgba);
    }
    throw new Error("Bad Hex");
};

/**
 *
 * @param rgba
 * @returns string
 */
export const rgbaToString = (rgba: any) => {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
};

/**
 *
 * @param hex
 * @returns string
 */
export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? rgbToString({
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          })
        : null;
};

/**
 *
 * @param rgb
 * @returns string
 */
export const rgbToString = (rgb: any) => {
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};
