const legacyBaseUrl = process.env.VUE_APP_LEGACY_RD;

export const AppUrls = {

    Dashboard: `/dashboard`,
    Activities: `/activities`,
    Unauthorized: `/unauthorized`,

    Account: {
        Login: `/account/login`,
        Logout: `/account/logout`,
        Lockout: `/account/lockout`,
        ForgotPassword: `/account/forgot-password`,
        ForgotPasswordConfirmation: `/account/forgot-password-confirmation`,
        ResetPassword: `/account/reset-password`,
        ResetPasswordConfirmation: `/account/reset-password-confirmation`,
        SsoCallback: `/account/sso-callback`,
        ExchangeToken: `/account/exchange-token`,
        Verify: `/account/verify`,
        FirstLogin: `/account/first-login`,
        FirstLoginConfirmation: `/account/first-login-confirmation`,
    },

    Campaigns: {
        List: `/campaigns`,
        View: `/campaigns/:id`,
        ViewCampaign: (id: number) => `/campaigns/${id}`,
        Create: `/campaigns/create`,
        Edit: `/campaigns/:id/edit`,
    },

    Donors: {
        List: `/donors`,
        View: `/donors/:id`,
        ViewDonor: (id: number) => `/donors/${id}`,
        Edit: `/donors/:id/edit`,
        ViewRecurringGiftRoute: '/donors/:id/recurring-gift/:recurringGiftId',
        ViewRecurringGift: (id: number, recurringGiftId: number) => `/donors/${id}/recurring-gift/${recurringGiftId}`
    },

    Exports: {
        List: `/exports`,
        ScheduledExportList: `/exports/scheduled`,
        ViewScheduledExport: `/exports/scheduled/:id`,
    },

    Projects: {
        List: `/projects`,
        View: `/projects/:id/:tab?`,
        ViewProject: (id: number, tab = '') => `/projects/${id}/${tab}`,
        Edit: `/projects/:id/edit`,
        Create: `/projects/create`
    },

    Premiums: {
        List: `/premiums`,
        Create: '/premiums/create',
        Edit: '/premiums/:id/edit',
        EditPremium: (id: number) => `/premiums/${id}/edit`,
    },

    Gifts: {
        List: '/gifts',
        View: `/gifts/:id`,
        ViewGift: (id: number) => `/gifts/${id}`,
        PartialLegacyReport: `${legacyBaseUrl}/admin/Page-Report.aspx?id=`,
        LegacyReport: (id: number) => `${AppUrls.Gifts.PartialLegacyReport}${id}`
    },

    Segments: {
        List: `/segments`,
        View: `/segments/:id/:tab?`,
        Edit: `/segments/:id/edit`,
    },

    Settings: {
        ApiKeys: {
            Create: `/tokens/create`,
            View: `/tokens/:id`,
            List: `/tokens`,
        },
        CustomFields: {
            List: `/settings/custom-fields`,
            Edit: `/settings/custom-fields/:id/edit`,
        },
        Dashboard: `/settings`,
        Design: `/settings/design`,
        DonorPortalSettings: `/settings/donor-portal-settings`,
        Integrations: {
            ListView: `/settings/integrations`,
            Virtuous: `/settings/integrations/virtuous`,
            VirtuousCallback: `/settings/integrations/virtuous-callback`,
            DoubleTheDonation: `/settings/integrations/doublethedonation`,
            GoogleAnalytics: `/settings/integrations/googleanalytics`
        },
        Organization: {
            View: `/settings/organization`
        },
        PaymentProviders: {
            List: `/settings/payment-providers`,
            Create: `/settings/payment-providers/create`,
            Edit: `/settings/payment-providers/:id/edit`,
            Return: `/settings/payment-providers/return`,
            Refresh: `/settings/payment-providers/refresh`,

            LinkPayPal: `/settings/payment-providers/link-paypal`,
            RefreshPayPal: `/settings/payment-providers/refresh-paypal`,
            ReturnPayPal: `/settings/payment-providers/return-paypal`,
            CreateSecondary: '/settings/payment-providers/create-secondary',
            EditSecondary: '/settings/payment-providers/:id/edit-secondary',
        },
        Sso: {
            Configure: `/settings/sso`,
        },
        Terminal: {
            List: `/settings/terminal`,
            Create: `/settings/terminal/create`,
            Edit: `/settings/terminal/:id/edit`,
        },
        Users: {
            List: `/settings/users`,
            Edit: `/settings/users/:id/edit`,
            EditEmail: `/settings/users/:id/edit-email`,
            EditPhone: `/settings/users/:id/edit-phone`,
            EditOwnAccount: `/settings/users/edit`,
            Create: `/settings/users/create`
        },
        Webhooks: {
            List: `/webhooks`,
            Create: `/webhooks/create`,
            View: `/webhooks/:id`,
            Edit: `/webhooks/:id/edit`,
            Logs: `/webhooks/logs`,
            LogView: `/webhooks/:id/log/:logid`,
        },
        WhiteLabel: '/settings/white-label',
        Notifications: '/settings/notification',
    },

    Pages: {
        List: `/pages`,
        Create: `/pages/builder`,
        Edit: `/pages/builder/:id`,
        EditPage: (id: number) => `/pages/builder/${id}`,
        PreviewRoute: `/pages/preview/:id`,
        Preview: (id, versionId = '') => `/pages/preview/${id}?versionId=${versionId}`,
        Versions: `/pages/:id/versions`,
        PageVersions: (id: number) => `/pages/${id}/versions`
    },

    RecurringGifts: {
        View: `/recurring-gift/:recurringGiftId`,
        Edit: `/recurring-gifts/:id/edit`,
        EditRecurringGift: (id: number) => `/recurring-gifts/${id}/edit`,
    },

    EmailTemplate: {
        List: '/email-templates',
        Create: '/email-templates/create',
        Edit: '/email-templates/:id/edit',
        Settings: '/email-templates/settings',
    },

    VirtualTerminal: {
        View: `/virtual-terminal`,
        ViewTerminal: `/virtual-terminal/:id`,
        ViewTerminalById: (id: number) => `/virtual-terminal/${id}`,
    },

    ProductFeedback: {
        View: `/product-feedback`,
    }
};
