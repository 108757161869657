export enum WidgetFilterType {
    Project = 0,
    Campaign = 1,
    Segment = 2,
    Page = 3,
    DonorType = 4,
    MinDonation = 5,
    MaxDonation = 6,
    Premium = 7,
    PaymentType = 8,
}