export enum Fields {
    FirstName = "firstName",
    LastName = "lastName",
    Email = "email",
    Address = "address",
    Address2 = "address2",
    Phone = "phone",
    DonorCoversCost = "donorPaysCost",
    DonationAmount = "donationAmount",
    Frequency = "frequency",
    City = "city",
    State = "state",
    Postal = "postal",
    Country = "country",
    AddressType = "addressType",
}
