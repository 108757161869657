export enum DashboardWidgetType {
    TotalUniqueVisitors = 1,
    TotalNumberGifts = 2,
    ConversionRate = 3,
    AverageGiftAmount = 4,
    TotalGiftsAmount = 5,
    Top5PagesRaisingMostMoney = 6,
    Top5Donors = 7,
    DonorType = 8,
    GiftType = 9,
    DonorSummary = 10,
    Top5Campaigns = 11,
    Top5Segments = 12,
    Top5Projects = 13,
    GivingComparison = 14,
    TotalRevenue = 15,
}
