export enum GiftStatus {
    Unknown = 0,
    Approved = 1,
    Declined = 2,
    Voided = 3,
    Refunded = 4,
    Error = 5,
    HeldForReview = 6,
    ChargeBack = 7,
    Pending = 8,
    Disputed_Won = 9,
    Disputed = 10,
    Disputed_Lost = 11,
    Fraud = 12,
}
