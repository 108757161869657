import { ICustomComponent, IPage } from "@/builder/interfaces";
import { getEmptyComponent } from "@/builder/BuilderUtilities";
import { ComponentType } from "../../enums";

export interface IIconComponent extends ICustomComponent {
    controls: {
        canMove: true;
        canEdit: true;
        canDelete: true;
        allowChildren: false;
        allowMultiple: true;
    };
    customData: {
        icon: string;
        size: number;
    };
    errors: [];
}

export const getIconComponent = (page: IPage) =>
    getEmptyComponent(ComponentType.Icon, page) as IIconComponent;
