export enum QueryType {
    Other = 0,
    Donor = 1,
    DonorAddress = 2,
    Gift = 3,
    Project = 4,
    Premium = 5,
    Page = 6,
    RecurringGift = 7,
    Campaign = 8,
    Segment = 9,
}