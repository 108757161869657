import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["role", "aria-live"]
const _hoisted_2 = { class: "content flex" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm font-semibold block mb-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    role: _ctx.setToastStatus,
    "aria-atomic": "true",
    "aria-live": _ctx.setToastAriaLive,
    class: _normalizeClass(["toast bounce-in-right opacity-100", _ctx.setToastType]),
    ref: "toastItem"
  }, [
    _createElementVNode("section", _hoisted_2, [
      (_ctx.hasIcon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["mr-2.5", _ctx.setToastIconClass])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
      ]),
      (_ctx.hasCloseButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "flex close-toast ml-2.5",
            "aria-label": "Close message",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeToast && _ctx.closeToast(...args)))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 24 24",
              fill: "currentColor",
              "aria-hidden": "true"
            }, [
              _createElementVNode("path", { d: "M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z" })
            ], -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}