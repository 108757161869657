<template>
    <div :role="setToastStatus" aria-atomic="true" :aria-live="setToastAriaLive"
        class="toast bounce-in-right opacity-100" :class="setToastType" ref="toastItem">

        <section class="content flex">
            <i v-if="hasIcon" class="mr-2.5" :class="setToastIconClass"></i>

            <div>
                <span v-if="title" class="text-sm font-semibold block mb-1">{{ title }}</span>
                {{ text }}
            </div>

            <button v-if="hasCloseButton" type="button" class="flex close-toast ml-2.5" aria-label="Close message"
                @click="closeToast">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path
                        d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z">
                    </path>
                </svg>
            </button>
        </section>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { ToastType } from '@/components/shared/toast/interfaces';
import { useGlobalStore } from '../../../stores/globalStore';
import { useRenderStore } from '../../../builder/stores/renderStore';

export default defineComponent({
    name: 'VueToastItem',
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String
        },
        text: {
            type: String,
            required: true
        },
        type: {
            type: String,
        },
        hasIcon: {
            type: Boolean
        },
        iconClass: {
            type: String
        },
        hasCloseButton: {
            type: Boolean
        },
        duration: {
            type: Number,
            required: true
        },
        storeSelection: {
            type: String,
            required: true,
            default: 'global'
        }
    },
    setup(props) {
        switch (props.storeSelection) {
            case 'global':
                return {
                    store: useGlobalStore()
                };
            case 'render':
                return {
                    store: useRenderStore()
                };
            default:
                return {
                    store: useGlobalStore()
                };
        }
    },
    data() {
        return {
            timeout: null as any
        }
    },
    mounted() {
        this.timeout = setTimeout(() => {
            this.closeToast();
        }, this.duration);
    },
    computed: {
        setToastStatus() {
            return this.type?.toLowerCase() == 'error' ? 'alert' : 'status';
        },

        setToastAriaLive() {
            return this.type?.toLowerCase() == 'error' ? 'assertive' : 'polite';
        },

        setToastType() {
            switch (this.type) {
                case ToastType.Error:
                    return 'toast-error';
                case ToastType.Info:
                    return 'toast-info';
                case ToastType.Success:
                    return 'toast-success';
                case ToastType.Warning:
                    return 'toast-warning';
                default:
                    return null;
            }
        },

        setToastIconClass() {
            const currentType = this.type?.toLowerCase();

            switch (currentType) {
                case ToastType.Error:
                    return 'ri-alert-fill';
                case ToastType.Info:
                    return 'ri-information-fill';
                case ToastType.Success:
                    return 'ri-checkbox-circle-fill';
                case ToastType.Warning:
                    return 'ri-error-warning-fill';
                default:
                    return this.iconClass;
            }
        }
    },
    methods: {
        closeToast() {
            if (this.timeout) clearTimeout(this.timeout);
            const currentToast = this.$refs.toastItem as HTMLElement;

            if (!currentToast) return;

            currentToast.classList?.remove('bounce-in-right');
            currentToast.classList?.remove('opacity-100');

            setTimeout(() => {
                this.store.clearToast(this.id);
            }, 500);
        }
    }
});
</script>

<style scoped>
.opacity-100 {
    opacity: 1 !important;
}
</style>
