import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastItem = _resolveComponent("ToastItem")!

  return (_ctx.toasts)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["toast-container", _ctx.parsedPosition])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
          return (_openBlock(), _createBlock(_component_ToastItem, {
            key: toast.id,
            id: toast.id,
            title: toast.title,
            text: toast.text,
            type: toast.type,
            hasIcon: toast.hasIcon,
            iconClass: toast.iconClass,
            hasCloseButton: toast.hasCloseButton,
            duration: toast.duration,
            storeSelection: _ctx.storeSelection
          }, null, 8, ["id", "title", "text", "type", "hasIcon", "iconClass", "hasCloseButton", "duration", "storeSelection"]))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}