export enum ParameterType {
    Boolean,
    Int,
    Date,
    Decimal,
    String,
    Object,
    List,
    Lookup,
    MultiLookup
}