export enum BuilderTypes {
    EmbedSimpleGiving,
    ModalGiving,
    EmbedSimpleLead,
    ModalLead,
    PageSimpleGiving,
    PageModalGiving,
    PageSimpleLead,
    PageModalLead
}
