export enum PaymentTenderType {
    None = 0,
    CC = 1,
    ACH = 2,
    CcAndAch = 3,
    Cash = 4,
    Check = 5,
    Stock = 6,
    Other = 7,
    Plaid = 8,
    PayPalOrVenmo = 9,
    Free = 10,
}