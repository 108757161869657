export enum IntegrationType {
    Virtuous = 0,
    Aegis = 1,
    Kindful = 2,
    Salesforce = 3,
    HubSpotForms = 4,
    MailChimp = 5,
    ConstantContact = 6,
    DoubleTheDonation = 7,
    Zapier = 8,
    SmartyStreets = 9,
    GoogleAnalytics = 10
}
