export enum ToastType {
    Success = "success",
    Error = "error",
    Warning = "warning",
    Info = "info"
}

export interface Toast {
    id: string;
    title: string | null;
    text: string;
    type: ToastType;
    hasIcon: boolean;
    iconClass: string | null;
    hasCloseButton: boolean;
    duration: number | null;
}

export enum ToastPosition {
    TopLeft = "top-left",
    TopRight = "top-right",
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right"
}