import { EmailDesign } from "@/stores/emailBuilderStore";

export const DefaultLeadConfirmationEmail = (design: EmailDesign) => {
    return {
        page: {
            body: {
                container: { style: { "background-color": "#FFFFFF" } },
                content: {
                    computedStyle: {
                        align: "center",
                        linkColor: "#0015ADE8",
                        messageBackgroundColor: "transparent",
                        messageWidth: "600px",
                    },
                    style: {
                        color: "#000000",
                        "font-family":
                            "'Helvetica Neue', Helvetica, Arial, sans-serif",
                    },
                },
                webFonts: [],
            },
            description: "",
            rows: [
                {
                    columns: [
                        {
                            "grid-columns": 12,
                            modules: [
                                {
                                    descriptor: {
                                        computedStyle: {
                                            hideContentOnAmp: false,
                                            hideContentOnDesktop: false,
                                            hideContentOnHtml: false,
                                            hideContentOnMobile: false,
                                        },
                                        html: {
                                            html: `<img src="${design.logoUrl}" alt="Organization Logo" style="width:25%;" />`,
                                        },
                                        style: {
                                            "padding-bottom": "0px",
                                            "padding-left": "0px",
                                            "padding-right": "0px",
                                            "padding-top": "0px",
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-html",
                                    uuid: "4f53ebf3-de7d-49e5-a327-c00665b1684d",
                                },
                            ],
                            style: {
                                "background-color": "transparent",
                                "border-bottom": "0px solid transparent",
                                "border-left": "0px solid transparent",
                                "border-right": "0px solid transparent",
                                "border-top": "0px solid transparent",
                                "padding-bottom": "5px",
                                "padding-left": "0px",
                                "padding-right": "0px",
                                "padding-top": "35px",
                            },
                            uuid: "74551b9e-fef2-4e5c-a361-26e535a4c03b",
                        },
                    ],
                    container: {
                        style: {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-position": "top left",
                            "background-repeat": "no-repeat",
                        },
                    },
                    content: {
                        computedStyle: {
                            hideContentOnDesktop: false,
                            hideContentOnMobile: false,
                            rowColStackOnMobile: true,
                            rowReverseColStackOnMobile: false,
                            verticalAlign: "top",
                        },
                        style: {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-position": "top left",
                            "background-repeat": "no-repeat",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-radius": "0px",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            color: "#000000",
                            width: "600px",
                        },
                    },
                    empty: false,
                    locked: false,
                    synced: false,
                    type: "one-column-empty",
                    uuid: "2c06d090-5168-4ba4-bf6d-0df240835f78",
                },
                {
                    columns: [
                        {
                            "grid-columns": 12,
                            modules: [
                                {
                                    descriptor: {
                                        computedStyle: {
                                            hideContentOnMobile: false,
                                        },
                                        style: {
                                            "padding-bottom": "8px",
                                            "padding-left": "30px",
                                            "padding-right": "30px",
                                            "padding-top": "25px",
                                        },
                                        text: {
                                            computedStyle: {
                                                linkColor: "#0015ADE8",
                                            },
                                            html: '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;"><span style="font-size: 26px; line-height: 31px;" data-mce-style="font-size: 26px; line-height: 31px;">Thanks for your interest!<br></span></p></div>',
                                            style: {
                                                color: "#333333",
                                                "font-family": "inherit",
                                                "line-height": "120%",
                                            },
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-text",
                                    uuid: "f5e2b549-5855-439f-bb93-0adc68c121d6",
                                },
                                {
                                    descriptor: {
                                        computedStyle: {
                                            hideContentOnMobile: false,
                                        },
                                        style: {
                                            "padding-bottom": "10px",
                                            "padding-left": "30px",
                                            "padding-right": "30px",
                                            "padding-top": "10px",
                                        },
                                        text: {
                                            computedStyle: {
                                                linkColor: "#0015ADE8",
                                            },
                                            html: '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 21px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 21px; font-family: inherit;"><p style="font-size: 14px; line-height: 21px; word-break: break-word; text-align: left;" data-mce-style="font-size: 14px; line-height: 21px; word-break: break-word; text-align: left;">We appreciate your interest in {{OrganizationName}}.<br>You can expect more information from our team soon.</p></div>',
                                            style: {
                                                color: "#555555",
                                                "font-family": "inherit",
                                                "line-height": "150%",
                                            },
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-text",
                                    uuid: "bab40e4f-fd53-4769-aefe-1dfb78f276ca",
                                },
                            ],
                            style: {
                                "background-color": "transparent",
                                "border-bottom": "0px solid transparent",
                                "border-left": "0px solid transparent",
                                "border-right": "0px solid transparent",
                                "border-top": "0px solid transparent",
                                "padding-bottom": "0px",
                                "padding-left": "0px",
                                "padding-right": "0px",
                                "padding-top": "0px",
                            },
                            uuid: "69d56715-0cce-494b-ace4-3dcb70e49ca5",
                        },
                    ],
                    container: {
                        style: {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-position": "top left",
                            "background-repeat": "no-repeat",
                        },
                    },
                    content: {
                        computedStyle: {
                            hideContentOnDesktop: false,
                            hideContentOnMobile: false,
                            rowColStackOnMobile: true,
                            rowReverseColStackOnMobile: false,
                            verticalAlign: "top",
                        },
                        style: {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-position": "top left",
                            "background-repeat": "no-repeat",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-radius": "0px",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            color: "#000000",
                            width: "600px",
                        },
                    },
                    empty: false,
                    locked: false,
                    synced: false,
                    type: "one-column-empty",
                    uuid: "746a1f5b-9a4b-4fa7-a089-787c6d494348",
                },
                {
                    columns: [
                        {
                            "grid-columns": 12,
                            modules: [
                                {
                                    descriptor: {
                                        computedStyle: {
                                            align: "center",
                                            hideContentOnMobile: false,
                                        },
                                        divider: {
                                            style: {
                                                "border-top":
                                                    "1px solid #DADADA",
                                                width: "100%",
                                            },
                                        },
                                        style: {
                                            "padding-bottom": "16px",
                                            "padding-left": "0px",
                                            "padding-right": "0px",
                                            "padding-top": "16px",
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-divider",
                                    uuid: "4aafa68a-16ec-4f20-a9fe-1e83f81fd7af",
                                },
                                {
                                    descriptor: {
                                        computedStyle: {
                                            hideContentOnMobile: false,
                                        },
                                        style: {
                                            "padding-bottom": "7px",
                                            "padding-left": "30px",
                                            "padding-right": "30px",
                                            "padding-top": "0px",
                                        },
                                        text: {
                                            computedStyle: {
                                                linkColor: "#0015ADE8",
                                            },
                                            html: '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;"><span style="font-size: 18px; line-height: 21px;" data-mce-style="font-size: 18px; line-height: 21px;">Learn More About Us</span></p></div>',
                                            style: {
                                                color: "#333333",
                                                "font-family": "inherit",
                                                "line-height": "120%",
                                            },
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-text",
                                    uuid: "a34dc1a3-d70e-44fd-a60b-1039ef737e92",
                                },
                                {
                                    descriptor: {
                                        computedStyle: {
                                            hideContentOnMobile: false,
                                        },
                                        style: {
                                            "padding-bottom": "5px",
                                            "padding-left": "30px",
                                            "padding-right": "30px",
                                            "padding-top": "0px",
                                        },
                                        text: {
                                            computedStyle: {
                                                linkColor: "#0015ADE8",
                                            },
                                            html: '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 21px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 21px; font-family: inherit;"><p style="font-size: 14px; line-height: 21px; word-break: break-word; text-align: left;" data-mce-style="font-size: 14px; line-height: 21px; word-break: break-word; text-align: left;"><span style="font-size: 15px; line-height: 22px;" data-mce-style="font-size: 15px; line-height: 22px;">In the meantime, visit our website to learn more about our work.</span></p></div>',
                                            style: {
                                                color: "#555555",
                                                "font-family": "inherit",
                                                "line-height": "150%",
                                            },
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-text",
                                    uuid: "ea1c6f83-9597-447e-9445-9f2e32dfe547",
                                },
                                {
                                    descriptor: {
                                        button: {
                                            href: "{{OrganizationUrl}}",
                                            label: '<div class="txtTinyMce-wrapper" style="" data-mce-style=""><p dir="ltr" style="word-break: break-word;" data-mce-style="word-break: break-word;">Learn More</p></div>',
                                            percWidth: 32,
                                            style: {
                                                "background-color": "#333333",
                                                "border-bottom":
                                                    "0px solid transparent",
                                                "border-left":
                                                    "0px solid transparent",
                                                "border-radius": "30px",
                                                "border-right":
                                                    "0px solid transparent",
                                                "border-top":
                                                    "0px solid transparent",
                                                color: "#FFFFFF",
                                                direction: "ltr",
                                                "font-family": "inherit",
                                                "font-size": "15px",
                                                "font-weight": "400",
                                                "line-height": "200%",
                                                "max-width": "100%",
                                                "padding-bottom": "5px",
                                                "padding-left": "35px",
                                                "padding-right": "35px",
                                                "padding-top": "5px",
                                                width: "auto",
                                            },
                                            target: "_blank",
                                        },
                                        computedStyle: {
                                            height: 40,
                                            hideContentOnMobile: false,
                                            width: 146,
                                        },
                                        style: {
                                            "padding-bottom": "10px",
                                            "padding-left": "30px",
                                            "padding-right": "10px",
                                            "padding-top": "10px",
                                            "text-align": "left",
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-button",
                                    uuid: "19aae157-f80c-445f-9fd4-47deeaf2dd0b",
                                },
                                {
                                    descriptor: {
                                        computedStyle: {
                                            align: "center",
                                            hideContentOnMobile: false,
                                        },
                                        divider: {
                                            style: {
                                                "border-top":
                                                    "1px solid #DADADA",
                                                width: "100%",
                                            },
                                        },
                                        style: {
                                            "padding-bottom": "25px",
                                            "padding-left": "0px",
                                            "padding-right": "0px",
                                            "padding-top": "35px",
                                        },
                                    },
                                    locked: false,
                                    type: "mailup-bee-newsletter-modules-divider",
                                    uuid: "7fed81eb-deac-4502-b7e9-3b93dc3fb627",
                                },
                            ],
                            style: {
                                "background-color": "transparent",
                                "border-bottom": "0px solid transparent",
                                "border-left": "0px solid transparent",
                                "border-right": "0px solid transparent",
                                "border-top": "0px solid transparent",
                                "padding-bottom": "5px",
                                "padding-left": "0px",
                                "padding-right": "0px",
                                "padding-top": "5px",
                            },
                            uuid: "8aac2aaf-ce34-455b-b15e-567284debe8b",
                        },
                    ],
                    container: {
                        style: {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-position": "top left",
                            "background-repeat": "no-repeat",
                        },
                    },
                    content: {
                        computedStyle: {
                            hideContentOnDesktop: false,
                            hideContentOnMobile: false,
                            rowColStackOnMobile: true,
                            rowReverseColStackOnMobile: false,
                            verticalAlign: "top",
                        },
                        style: {
                            "background-color": "transparent",
                            "background-image": "none",
                            "background-position": "top left",
                            "background-repeat": "no-repeat",
                            "border-bottom": "0px solid transparent",
                            "border-left": "0px solid transparent",
                            "border-radius": "0px",
                            "border-right": "0px solid transparent",
                            "border-top": "0px solid transparent",
                            color: "#000000",
                            width: "600px",
                        },
                    },
                    empty: false,
                    locked: false,
                    synced: false,
                    type: "one-column-empty",
                    uuid: "235c8224-8a05-4024-805f-bdbd50c69a91",
                },
            ],
            template: { version: "2.0.0" },
            title: "",
        },
        comments: {},
    };
};
